$(document).foundation();

var topBar = document.querySelector('#topBar');
var topBarClose = document.querySelector('#topBar .healthInfo__close');

topBarClose.addEventListener("click", function() {
    topBar.classList.add('hideTopBar');
    setCookie("healthProtocols", "yes", 3);
});

// Cookie for SlideInBanner
function setCookie(cname,cvalue,exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires=" + d.toGMTString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function checkCookie() {
    var bannerShow = getCookie("healthProtocols");
    if (bannerShow == "") {
        topBar.classList.remove('hideTopBar');
    }
}

$(function() {
    // Menu Toggle On/Off
    $('.header__toggler').on('click', function() {
        $('body').toggleClass('openMenu');
        $('.header').toggleClass('openMenu');
    });

    // Menu Language Switcher
    $('.header__aside--lang-heading').on('click', function() {
        $('.header__aside--lang-list').toggleClass('showLangs');
    });
    

    // Go Top Button
    $('.goTop').on('click', function(){
        $('html, body').animate({ scrollTop: 0 }, 400);
    });

    // On Page Scroll (Desktop)
    $(document).on('scroll', function() {
        if ($(document).scrollTop() >= 1000) {
            $('.goTop').removeClass('hideButton');
        } else {
            $('.goTop').addClass('hideButton');
        }
    });
    
    checkCookie();
});